<template>
    <zw-input-group v-model="inputVal.price"
                    name="price"
                    disable-label
                    type="number"
                    min="0"
                    step="0.01"
                    size="sm"
                    @change="savePosition('price',inputVal)"
                    :readonly="readOnly"
    ></zw-input-group>
</template>

<script>
export default {
    name: 'PriceColumn',
    props: {
        value: [Object],
        savePosition: [Function],
        readOnly: [Boolean],
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>